/**
 * Setup webpack public path
 * to enable lazy-including of
 * js chunks
 *
 */
/* ----global urls:true---- */
import 'bootstrap';

const $ = window.jQuery;

$(() => {
	// console.log('test');
});

$(window).resize(() => {

});
